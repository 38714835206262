import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RouterPrivate } from './private';
import { PageLogin } from '@/pages/login';
import { PageRegister } from '@/pages/register';
import { PageUser } from '@/pages/user';
import { PageHomeUser } from '@/pages/user/home/user';
import { PageHistoryUser } from '@/pages/user/history';
import { PageCollaborators } from '@/pages/collaborators';
import { PageCollaboratorsView } from '@/pages/collaborators/view';
import { useAPP } from '@/services/provider/app';
import { api, URL_USER_PROFILE } from '@/services/provider/api';
import { PageFeedback } from '@/pages/feedback/adm';
import { PageFeedbackUser } from '@/pages/feedback/user';
import { PageFeedbackView } from '@/pages/feedback/adm/view';
import { PageFeedbackQuestions } from '@/pages/feedback/questions';
import { PageFeedbackViewUser } from '@/pages/feedback/adm/viewuser';
import { PageFeedbackAnswer } from '@/pages/feedback/answer';

export const NavigationRoute = () => {

    
    const sitemap = useMemo(()=> ({
        PageHomeUser: <PageHomeUser />,
        PageHistoryUser: <PageHistoryUser />,
        PageCollaborators: <PageCollaborators />,
        PageCollaboratorsView: <PageCollaboratorsView />,
        PageFeedback: <PageFeedback />,
        PageFeedbackQuestions: <PageFeedbackQuestions />,
        PageFeedbackView: <PageFeedbackView />,
        PageFeedbackViewUser: <PageFeedbackViewUser />,
        PageFeedbackAnswer: <PageFeedbackAnswer />,
        PageFeedbackUser: <PageFeedbackUser />,




    }),[]);

    const {user, setUser} = useAPP();

    const [routerData, setRouterData] = useState();
    const [routerMainData, setRouterMainData] = useState();
    const [routerupdate, setRouterupdate] = useState(new Date().getTime());

    const getRouter = useCallback(async() => {

        const router = (
                    <Route path='/user' element={<RouterPrivate><PageUser /></RouterPrivate>} >
                        {
                            user?.access?.map((item, id) => {
                                const isExists  = sitemap[item.component] ? true : false;
                                
                                if(isExists){
                                    return <Route key={`ref-id-${item.id}`} path={item.url} element={sitemap[item.component]} />
                                }else{
                                    return <Route key={`ref-id-${item.id}`} path={item.url} element={<>Página não encontrada</>} />   
                                }

                            })
                        }
                        <Route path='*' element={<>Página não encontrada</>} />
                    </Route>
        );


        setRouterData(router);
        
    }, [sitemap, user]);

    const getMainRouter = useCallback(()=> {
        const base = (
            <BrowserRouter >
                <Routes>
                    {routerData}
                    <Route path='/login' element={<PageLogin />} />
                    <Route path='' element={<PageRegister />} />
                    <Route path='*' element={<>Página não encontrada</>} />
                </Routes>
            </BrowserRouter>
        )

        setRouterMainData(base);
    }, [routerData, setRouterMainData])


    const getData = useCallback(async () => {
        if(!user){
            const data = await api.get(URL_USER_PROFILE, null, true);
            setUser(data.data);
            setRouterupdate(new Date().getTime());
        }
    },[setUser, setRouterupdate, user]);

    useEffect(()=>{
        getMainRouter();
    }, [routerupdate, getMainRouter]);

    useEffect(()=>{
        getData();
    }, [getData])

    useEffect(()=>{
        getRouter();
    }, [getRouter]);

    return(<div key={routerupdate}>
            {routerMainData}
        </div>
    )
}