import React, { useState, useEffect, useCallback} from "react";

import { Divider } from 'primereact/divider';
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import { api, URL_FEEDBACK_ALLQUESTIONS } from "@/services/provider/api";

export const PageFeedbackQuestions = () => {

    const [data, setData] = useState();
    const [showDirectional, setShowDirectional] = useState(false);
    const [showCH, setShowCH] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);

    const getData = useCallback(async () => {
        const list = await api.get(URL_FEEDBACK_ALLQUESTIONS, null, true);
        
        setData(list?.data);
    }, [])

    useEffect(()=>{
        getData();
    }, [getData])
    
    return (
        <div className="page-feedback-questions">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-star mr-2"></i>
                    <b>Feedback - Criar questões</b>
                </div>
            </Divider>
            <div className="grid">
                <div className="col-12">
                    <Toolbar left={()=>{
                            return <>
                            </>
                        }} 
                        right={()=>{
                            return <>
                                <Button onClick={()=>{setShowDirectional(true)}} label='Criar direcionador' className='p-button-sm p-button-primary mr-2' icon="pi pi-sitemap" />
                                <Button onClick={()=>{setShowCH(true)}} label='Criar Competência/ Habilidade' className='p-button-sm p-button-primary mr-2' icon="pi pi-file" />
                                <Button onClick={()=>{setShowQuestion(true)}} label='Criar questões' className='p-button-sm p-button-info mr-2' icon="pi pi-list" />
                            </>
                    }} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable rowGroupMode="rowspan" groupRowsBy="direction_name" size="small" paginator={data?.length > 50} rows={50} value={data || []} emptyMessage="Não há registros" >
                        <Column sortable field="direction_name" header="Direção" />
                        <Column sortable field="competence_hability_name" header="Competência/ Habilidade" />
                        <Column sortable field="question" header="Questão" />
                        <Column field="type" header="Tipo" />
                    </DataTable>
                </div>
            </div>
            <Dialog header={`Adicionar direcionador`} visible={showDirectional} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowDirectional(false)}>

            </Dialog>

            <Dialog header={`Adicionar competências e habilidades`} visible={showCH} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowCH(false)}>

            </Dialog>

            <Dialog header={`Adicionar Questão`} visible={showQuestion} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowQuestion(false)}>

            </Dialog>
        </div>
    )
}
