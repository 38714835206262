import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Toolbar } from 'primereact/toolbar'
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Avatar } from 'primereact/avatar';
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
        

import { localeData, secondsToTime, timeToSeconds } from '@/services/utils';
import { addLocale } from 'primereact/api';

import { utils, writeFile } from "xlsx";

import { api, URL_REGISTER_LIST, URL_USER_DELETE_CHECK } from '@/services/provider/api';

import { useAPP } from "@/services/provider/app";

import '@/assets/style/pages/history/index.scss';
import { Dropdown, InputTextarea } from 'primereact';
 

export const PageHistoryUser = (props) =>{

    addLocale('pt-br', localeData.br);

    const { user } = useAPP();

    const [history, setHistory] = useState();
    const [showNote, setShowNote] = useState(false);
    const [showEditLine, setShowEditLine] = useState(false);
    const [note, setNote] = useState();
    const [lineCurrent, setLineCurrent] = useState();

    const [isLoad, setIsLoad] = useState(false);

    const table = useRef();

    const toast = useRef(null);

    const date_temp = new Date();

    const [start, setStart] = useState(new Date(date_temp.getFullYear(), date_temp.getMonth(), 1));
    const [end, setEnd] = useState(new Date(date_temp.getFullYear(), date_temp.getMonth() + 1, 0));

    const openNote = (_data) => {
        setNote(_data?.note);
        setShowNote(true);
    }

    const getData = useCallback(async () => {
        const _start = new Date(start.getFullYear(), start.getMonth(), 1);
        const _end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
        const params = {
            start: _start?.toISOString()?.substr(0, 10), 
            end: _end?.toISOString()?.substr(0, 10)
        }
        
        const data = await api.get(`${URL_REGISTER_LIST}${props?.user ? `/${props?.user}` : ''}`, params, true);
        console.log('History', data?.data);
        setHistory(data?.data);

    }, [start, end, setHistory, props])

    const calculeteJourneyDay = (_data) => {

        console.log('calculate', _data);

        const journeyDay = _data?.journey;
        const lanch = _data.lunch;

        console.log('journeyDay', timeToSeconds(journeyDay), secondsToTime(timeToSeconds(journeyDay)));

        let currentList = history.filter(item => _data.date.toString() === item.date.toString());
        //console.log(currentList);
        // currentList = currentList.reverse(); 
        let sumDayList = currentList.map(item => timeToSeconds(item.time));
        sumDayList = sumDayList.sort().reverse();

        let sumDay = sumDayList.reduce((a, n, i) => i === 0 ? n : a - n, 0);
        sumDay = sumDay - (timeToSeconds(lanch)); 

        let extra =  sumDay - timeToSeconds(journeyDay);
        console.log('Extra', sumDay, extra);

        console.log('List', sumDayList, secondsToTime(sumDay));

        // currentList.map(item => {
        //     if(sumDay)
        //         sumDay -= (~~item.time.split(':')[0] * 60) + (~~item.time.split(':')[1]);
        //     else
        //         sumDay = (~~item.time.split(':')[0] * 60) + (~~item.time.split(':')[1]);

        //     return item;
        // })

        //  currentList.map(item => {
        //     if(sumDay)
        //         sumDay -= (~~item.time.split(':')[0] * 60) + (~~item.time.split(':')[1]);
        //     else
        //         sumDay = (~~item.time.split(':')[0] * 60) + (~~item.time.split(':')[1]);

        //     return item;
        // })

        // const totalDay = (~~journeyDay.split(':')[0] * 60) + (~~journeyDay.split(':')[1]) + (sumDay - timeDay);

        // const hour = `00${Math.floor(totalDay / 60)}`;
        // const minutos = `00${totalDay % 60}`;

        // const hourDiff = `00${Math.floor((sumDay - timeDay) / 60)}`;
        // const minutosDiff = `00${(sumDay - timeDay) % 60}`;

        const [year, month, day] = _data.date?.split('-'); 

        //return `${hour.substr(-2)}:${minutos.substr(-2)}`;

        return <div style={{backgroundColor:'var(--gray-100', color:'#111', alignItems:'center'}} className='grid'>
            <div className='col-9'>
                <strong>{(new Date(year, month - 1, day)).toLocaleDateString() }</strong>
            </div>
            <div className='col-1' style={{textAlign:'center'}}>
                {
                    currentList.length % 2 === 0 ?
                        `${secondsToTime(sumDay)}`
                    :
                        ''
                }
            </div>
            <div className='col-1' style={{textAlign:'center'}}>
                {
                    currentList.length % 2 === 0 ?
                        `${extra < 0 ? '-' : ''}${secondsToTime(Math.abs(extra))}`
                    :
                        ''
                }
            </div>
            <div className='col-1' style={{textAlign:'center'}}>
                {
                    currentList.length % 2 === 0 && currentList.length >= 1 ?
                        <>{(extra) === 0 ? <Avatar shape="circle" icon='pi pi-minus' style={{ backgroundColor: '#d1e7dd', color: '#0f5132' }} /> : ( (extra) < 0 ?  <Avatar shape="circle" icon='pi pi-arrow-down' style={{ backgroundColor: '#f8d7da', color: '#842029' }} /> :  <Avatar shape="circle" icon='pi pi-arrow-up' style={{ backgroundColor: '#cfe2ff', color: '#084298' }} />)}</>
                    :
                        <Avatar shape="circle" icon='pi pi-times' style={{ backgroundColor: '#ffe69c', color: '#664d03' }} />
                }
            </div>

        </div>
    }

    const editLine = (row) => {
        setLineCurrent(row);
        setShowEditLine(true);
    }

    const deleteCheck = useCallback(async (_data) => {

        const acceptFunc = async () => {
            
            setIsLoad(true);

            const result = await api.delete(`${URL_USER_DELETE_CHECK}/${_data.id}`, null , true);

            toast.current.show({severity:'success', summary: 'Sucesso', detail: result?.data, life: 3000});

            // if(~~result.data.status === 200){
            // }else{
            //     toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
            // }


            getData();
        }

        const rejectFunc = () => {}

        confirmDialog({
            message: `Deseja excluir o lançamento ${new Date(`${_data.date}  00:00:00`).toLocaleDateString()} ${_data.time}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () => rejectFunc()
        });
    }, [])

    const onExport = useCallback(async () => {

        let json_temp = {};
        let json = [];

    
        history.map(item => {

            if(json_temp[item.date]){
                json_temp[item.date].push(item);
            }else{
                json_temp[item.date] = [];
                json_temp[item.date].push(item)
            }

            return item;
        })

        // Object.keys(json_temp).map((key, idx) => {
        //     const total = json_temp[key][0]?.time && json_temp[key][1]?.time ? timeToSeconds(json_temp[key][1]?.time) - timeToSeconds(json_temp[key][0]?.time) : null
        //     json.push(
        //         {
        //             'Data':key.split('-').reverse().join('/'),
        //             'Entrada': json_temp[key][0]?.time,
        //             'Almoço Saida': '12:00',
        //             'Almoço Entrada': '13:15',
        //             'Saida': json_temp[key][1]?.time
        //         }
        //     )
        // })

        json.push(['Data','Entrada','Almoço Saida','Almoço Entrada','Saida','Jornada', 'Total', 'Saldo']);

        Object.keys(json_temp).map((key, idx) => {
            const total = json_temp[key][0]?.time && json_temp[key][1]?.time ? timeToSeconds(json_temp[key][1]?.time) - timeToSeconds(json_temp[key][0]?.time) : null
            json.push(
                [
                    key?.split('-')?.reverse()?.join('/'),
                    json_temp[key][0]?.time,
                    '12:00',
                    '13:15',
                    json_temp[key][1]?.time,
                    '8:48'
                ]
            )
        })

        json =  json.map((item, idx) =>{

            if(idx > 0){
                item.push({t:'n', f:`(E${idx+1} - D${idx+1}) + (C${idx+1} - B${idx+1})`, z:"[h]:mm:ss"});
                item.push({t:'n', f:`(G${idx+1} - F${idx+1})`, z:"[h]:mm:ss"});
            }

            return item;
        })

        // json =  json.map((item, idx) =>{

        //     if(idx > 0)
        //         item.total = {t:'n', f:`(E${idx} - D${idx}) + (C${idx} - B${idx})`, z:"[h]:mm:ss"};

        //     return item;
        // })

        console.log(json);

        const wb = utils.book_new();
        wb.Workbook = {WBProps:{date1904: true}};
        console.log(wb);
        const ws = utils.aoa_to_sheet(json);
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, `history_${props.user}_${new Date().getTime()}.xlsx`);
    },[history, utils, writeFile, props])

    useEffect(()=>{

        getData();

    }, [getData, start, end]);

    return (
        <div className="page-history-user">
            
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-history mr-2"></i>
                    <b>Histórico</b>
                </div>
            </Divider>
            <div className="grid">
                <div className="col-12">
                    <Toolbar left={()=>{
                        return <>
                            <Calendar view="month" dateFormat='mm/yy' value={start}  onChange={(e)=> setStart(e.value)} locale='pt-br' showIcon  />
                            {/* <i className='pi pi-arrows-h mr-2 ml-2'/>
                            <Calendar dateFormat='dd/mm/yy' value={end} minDate={start} onChange={(e)=> setEnd(e.value)}  locale='pt-br' showIcon  /> */}
                        </>
                    }} 
                    right={()=>{
                        return <>
                            <Button onClick={onExport} label='Exportar' className='p-button-sm p-button-help' icon="pi pi-file" />
                        </>
                    }} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable  ref={table} 
                        rowGroupMode="subheader" 
                        groupRowsBy="date" 
                        size="small" 
                        paginator={history?.length > 100} 
                        rows={100} value={history || []} 
                        emptyMessage="Não há registros" 
                        rowGroupHeaderTemplate={(_data)=> calculeteJourneyDay(_data) }
                    >
                        {/* <Column style={{width:'120px'}} body={(row) => (new Date(`${row.date} 00:00:00`)).toLocaleDateString() } field="date" header="Data" /> */}
                        <Column style={{width:'120px'}} field="time" header="" />
                        <Column style={{width:'120px'}} field="type" header="" />
                        <Column field="note" header="" />
                        {/* <Column style={{width:'120px', textAlign:'center'}} body={(row)=> row?.review.toString() === '1' ? <Button onClick={()=>openNote(row)} icon="pi pi-exclamation-triangle" className='p-button-sm p-button-text p-button-primary' /> : ''} field="review" header=""></Column> */}
                        <Column style={{width:'120px', textAlign:'right'}} body={(row)=> { 
                           return row?.review.toString() === '1' ? 
                            (row?.aproved === '1' ? 
                                <i className='pi pi-check' /> : 
                                (row?.aproved === '0' ? 
                                    <i className='pi pi-times' /> 
                                : 
                                    <i className='pi pi-exclamation-triangle' />))  
                           : '' 
                        }} field="review" header="" />
                        <Column style={{width:'120px', textAlign:'right'}} body={(row)=> {
                            return <>
                                <Button onClick={()=> editLine(row)} className='p-button-sm p-button-text' icon="pi pi-pencil" />
                                <Button onClick={() => deleteCheck(row)} className='p-button-sm p-button-text' icon="pi pi-trash" />
                            </>
                        }} />
                    </DataTable>
                </div>
            </div>
            <Dialog header={`Comentário`} visible={showNote} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowNote(false)}>
                {note}
            </Dialog>
            <Dialog header={`Editar registro`} visible={showEditLine} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowEditLine(false)}>
                <div className="grid">
                    <div className="col-12">
                        <strong>
                            Horário
                        </strong>
                    </div>
                    <div className="col-4">
                        <Calendar timeOnly showTime value={new Date(`${lineCurrent?.date} ${lineCurrent?.time}`)} onChange={(e) => setLineCurrent({...lineCurrent, time: e.target.value})} />
                    </div>
                    <div className="col-12">
                        <strong>
                            Tipo
                        </strong>
                    </div>
                    <div className="col-12">
                        {
                            [
                                {label: 'Entrada', value: 'in'},
                                {label: 'Ausência', value: 'out'},
                                {label: 'Falta', value: 'out'},
                                {label: 'Férias', value: 'ferias'}
                            
                            ].map(item => {
                                return <div className='mt-2 ml-4' key={`radio-${item.label}`} >
                                    <RadioButton id={item.label} checked={lineCurrent?.type === item.label} value={item.label} onChange={(e) => setLineCurrent({...lineCurrent, type: e.target.value})} />
                                    <label htmlFor={item.label} className="ml-2">{item.label}</label>
                                </div>
                            })
                        }
                        
                    </div>
                    <div className="col-12">
                        <strong>
                            Justificativa
                        </strong>
                    </div>
                    <div className="col-12">
                        <InputTextarea 
                            style={{width:'100%'}}
                            autoResize
                            value={lineCurrent?.note} 
                            onChange={(e) => setLineCurrent({...lineCurrent, note: e.target.value})}
                        />
                    </div>
                    <div className="col-12" style={{textAlign:'right'}}>
                        <Button label='Atualizar' />
                    </div>
                </div>
            </Dialog>
            <Toast position="bottom-center" ref={toast} />
            <ConfirmDialog />
        </div>
    )
}