import React, { useRef, useEffect} from 'react';

import '@/assets/style/component/clock/index.scss';

export const Clock = () => {

    const timer = useRef();
    const timerupdate = useRef();

    useEffect(()=>{
        timerupdate.current = setInterval(()=>{
            const datetime = new Date();
            timer.current.querySelector('.date').innerHTML = datetime.toLocaleDateString();
            timer.current.querySelector('.time').innerHTML = datetime.toLocaleTimeString();
        }, 1000)

        return(()=>{
            clearInterval(timerupdate.current)
        })

    }, [])


    return (
        <div ref={timer} className="component-clock">
            <div className="date"></div>
            <div className="time"></div>
        </div>
    )
}
