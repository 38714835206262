import React, { useRef, useEffect, useState, useCallback, useMemo} from 'react';

import { Divider } from 'primereact/divider';
import { Knob } from 'primereact/knob';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';

import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';

import { useAPP } from '@/services/provider/app';
import { api, URL_REGISTER_BY_DAY,  URL_FIRST_REGISTER_BY_DAY, URL_LAST_REGISTER_BY_DAY, URL_REGISTER  } from '@/services/provider/api';

import '@/assets/style/component/journeyday/index.scss'

export const JourneyDay = () => {


    const { user } = useAPP();

    const timer = useRef();
    const timerupdate = useRef();
    const datatimerupdate = useRef();
    const progresstimerupdate = useRef();
    const timerjourney = useRef();

    const [progress, setProgress] = useState(0);
    const [first, setFirst] = useState();
    const [end, setEnd] = useState();
    const [preview, setPreview] = useState();
    const [details, setDetails] = useState();

    const [showNote, setShowNote] = useState(false);
    const [note, setNote] = useState();

    const [showDataDialog, setShowDataDialog] = useState(false);

    const [showRegister, setShowRegister] = useState(false);

    const [dataUpdate, setDataUpdate] = useState(0);
    const [update, setUpdate] = useState(0);

    const [isLoad, setIsLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(()=>({
        note: ''
    }), []);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);


    const openNote = (_data) => {
        setNote(_data?.note);
        setShowNote(true);
    }

    const getFirst = useCallback(async()=>{
        const data = await api.get(URL_FIRST_REGISTER_BY_DAY, null, true);
        setFirst(data?.data);
    }, [])
    
    const getEnd = useCallback(async()=>{
        const data = await api.get(URL_LAST_REGISTER_BY_DAY, null, true);
        setEnd(data?.data);
    }, [])

    const calculatePreview = useCallback(async()=>{
        if(first){
            const [hours, minutes] = first.split(':');
            const [jhours, jminutes] = user?.journey?.split(':');
            const date = new Date();
            date.setHours(~~hours + ~~jhours + 1);
            date.setMinutes(~~minutes + ~~jminutes + 12);
            const _hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const _minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            setPreview(`${_hours}:${_minutes}`);
        }
    }, [user, first, setPreview])

    const calculateProgresss = useCallback(async()=>{
        if(first && preview){
            const [fh, fm] = first.split(':');
            const [hours, minutes] = end ? end?.split(':') : (new Date().toLocaleTimeString()).split(':');
            const [jhours, jminutes] = preview?.split(':'); 

            const _start = (~~fh * 60) + ~~fm;
            const _current = (~~hours * 60) + ~~minutes;
            const _final = (~~jhours * 60) + ~~jminutes;

            const percent = Math.floor(Math.max(0, (_current - _start))  * 100 / (_final - _start));

            setProgress(percent);
        }else{
            setProgress(0);
        }
    }, [first, setProgress, preview, end])

    const registerList = useCallback(async() => {
        const _list = await api.get(`${URL_REGISTER_BY_DAY}`, null, true);
        setDetails(_list.data);
    }, [])

    const updateAll = useCallback(()=>{
        setIsLoad(true);
        clearTimeout(progresstimerupdate.current);

        getFirst();
        getEnd();
        calculatePreview();
        calculateProgresss();
        registerList();

        const datetime = new Date();    
        setDataUpdate(datetime.getTime());
        setUpdate(datetime.getTime());
        setIsLoad(false);

    }, [getFirst, getEnd, calculatePreview, calculateProgresss, registerList]);

    const onSubmit = useCallback(async (_data) => {
        const data = {..._data};
        data.id = user.id;
        setIsLoading(true);
        const result = await api.post(URL_REGISTER, data , false);
        
        setIsLoading(false);

        if(~~result.data.status === 200){
            toast.current.show({severity:'success', summary: 'Sucesso', detail: result.data.message, life: 3000});
            reset(defaultValues);
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
        }

    }, [defaultValues, reset, user]);

    useEffect(()=>{
        getFirst();
        getEnd();
        calculatePreview();
        calculateProgresss();
        registerList();
    }, [getFirst, getEnd, calculatePreview, calculateProgresss, registerList]);

    useEffect(()=>{
        timerupdate.current = setInterval(()=>{
            const datetime = new Date();
            timer.current.querySelector('.date').innerHTML = datetime.toLocaleDateString();
            timer.current.querySelector('.time').innerHTML = datetime.toLocaleTimeString();
            
            if(first){
                const [fh, fm] = first.split(':');
                const _start = (~~fh * 60) + ~~fm;
                const _end = end ? ((~~end.split(':')[0] * 60) + ~~end.split(':')[1]) : (datetime.getHours() * 60) + datetime.getMinutes() ;
                const _current = _end - _start;


                let _hours = Math.floor(_current / 60);
                let _minutes = _current % 60;

                _hours = _hours < 10 ? `0${_hours}` :_hours;
                _minutes =_minutes < 10 ? `0${_minutes}` : _minutes;

                timerjourney.current.innerHTML = `${_hours}:${_minutes}`;
                
            }

        }, 1000)

        return(()=>{
            clearInterval(timerupdate.current)
        })

    }, [first, end])

    const showData = () => {
        setShowDataDialog(true);
    }

    useEffect(()=>{
        progresstimerupdate.current = setTimeout(()=>{
            const datetime = new Date();            
            calculateProgresss();
            setDataUpdate(datetime.getTime());
        }, 1000)

        return(()=>{
            clearTimeout(progresstimerupdate.current)
        })

    }, [dataUpdate, calculateProgresss])

    useEffect(()=>{
        datatimerupdate.current = setTimeout(()=>{
            setIsLoad(true);
            const datetime = new Date();
            getFirst();
            getEnd();
            calculatePreview();
            registerList();
            setUpdate(datetime.getTime());
            setIsLoad(false);
        }, 5000)

        return(()=>{
            clearTimeout(datatimerupdate.current)
        })

    }, [update,getFirst, getEnd, calculatePreview, registerList])

    return(
        <div className="component-journey-day">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-stopwatch mr-2"></i>
                    <b>Hoje</b>
                </div>
            </Divider>
            <div className="box">
                <div className="clock">
                    <Knob valueTemplate={`${progress}%`} value={Math.max(0, Math.min(progress, 100))} min='0' max='100'  />
                    <div ref={timerjourney} className='time-day'></div>
                </div>
                <div className="content">
                    <div className='area-clock'>
                        <div ref={timer} className="clock">
                            <div className="date"></div>
                            <div className="time"></div>
                        </div>
                    </div>
                    <div className="area-journey">
                        Carga horária diaria: {user?.journey}
                    </div>
                    {/* <div className="area-register">
                        <Button onClick={updateAll} icon={`pi pi-sync ${isLoad ?  'pi-spin' : ''}`} label="Sincronizar" className='p-button-sm p-button-secondary mr-2' />
                        <Button onClick={()=>setShowRegister(true)} icon="pi pi-pencil" label='Efeturar registro' className='p-button-sm p-button-success' />
                    </div> */}
                    <div className="area-first">
                        <div className="title">Entrada</div>
                        <div>{first}</div>
                    </div>
                    <div className="area-lunch">
                        <div className="title">Almoço</div>
                        <div>{user?.lunch_start} - {user?.lunch_end}</div>
                    </div>
                    <div className="area-end">
                        <div className="title">Saída</div>
                        <div>{ end }</div>
                    </div>
                    <div className="area-prevision">
                        <div className="title">Previsão final dia</div>
                        <div>{preview}</div>
                    </div>
                    <div className="area-details">
                        <div className="title">Marcações</div>
                        <div>
                            <Button onClick={showData} icon="pi pi-list" className='p-button-text p-button-sm' />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Panel headerTemplate={(options)=>{
                return <div className='details' onClick={options.onTogglerClick}>
                    <div><i className={ options.collapsed ? 'pi pi-plus' : 'pi pi-minus'} /></div>
                    <div>Marcações</div>
                </div>
                }} 
                toggleable
                collapsed
                >
                {
                    details ?
                    <DataTable value={details} emptyMessage="Não há registros">
                        <Column body={(row)=> new Date(row.datetime).toLocaleTimeString() } field="datetime" header="Horário"></Column>
                        <Column body={(row)=> ((/in/gi).test(row.type) ? <Avatar style={{backgroundColor:'var(--green-100)', color:'var(--green-700)'}} shape='circle' icon="pi pi-plus-circle" /> : <Avatar style={{backgroundColor:'var(--red-100)', color:'var(--red-700)'}} shape='circle' icon="pi pi-minus-circle" /> )} field="type" header="Tipo"></Column>
                        <Column field="access" header="Origem"></Column>
                        <Column body={(row)=> row.note ? <Button onClick={()=>openNote(row)} icon="pi pi-book" className='p-button-sm p-button-rounded p-button-primary' /> : ''} field="note" header="Comentário"></Column>
                    </DataTable>
                    :
                    'Não há registros'
                }
            </Panel> */}
            <Dialog header={`Comentário`} visible={showNote} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowNote(false)}>
                {note}
            </Dialog>
            <Dialog header={`Efetuar registro`} visible={showRegister} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowRegister(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12 mt-4">
                        <Controller name="note" control={control} render={({ field }) => (
                            <InputTextarea {...field} id={field.name} placeholder="Informe um comentário" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                        )} />
                        </div>

                        <div className="col-12 mt-2" style={{textAlign:'right'}}> 
                            <Button loading={isLoading} label="Registrar presença" className="p-button-info" />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog header={`Marcações`} visible={showDataDialog} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowDataDialog(false)}>
                {
                    details ?
                    <DataTable value={details} emptyMessage="Não há registros">
                        <Column body={(row)=> new Date(row.datetime).toLocaleTimeString() } field="datetime" header="Horário"></Column>
                        <Column body={(row)=> row.note ? <Button onClick={()=>openNote(row)} icon="pi pi-book" className='p-button-sm p-button-rounded p-button-primary' /> : ''} field="note" header="Comentário"></Column>
                    </DataTable>
                    :
                    'Não há registros'
                }
            </Dialog>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}

