import React, { useState, useEffect, useCallback, useRef } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { localeData } from '@/services/utils';
import { addLocale } from 'primereact/api';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

import { FileUpload } from 'primereact/fileupload';
 
import { api, URL_REGISTER } from "@/services/provider/api";

import { useAPP } from "@/services/provider/app";

export const CheckOut = ({userId}) => {
    
    const { user } = useAPP();

    console.log('User', user);

    addLocale('pt-br', localeData.br);

    const datebase =  new Date();

    const [startDate, setStartDate] = useState(new Date(new Date(`${datebase.toISOString().substr(0, 10)} 08:00:00`)));
    const [endDate, setEndDate] = useState(new Date(new Date(`${datebase.toISOString().substr(0, 10)} 18:00:00`)));
    const listTypes = [
        {label:'Atestado', value:'atestado', isFile: true},
        {label:'Ausência', value:'ausencia', isFile: false},
        {label:'Falta', value:'falta', isFile: false}
    ];

    if(user?.id !== userId){
        listTypes.push({label:'Férias', value:'férias', isFile: false})
    }

    const [types, setType] = useState(listTypes);
    const [allDay, setAllDay] = useState(true);
    const [currentType, setCurrentType] = useState();
    const [isFile, setIsFile] = useState(false);



    const selectType = (_data) => {
        const _isFile = types?.filter(item => item.value === _data.value)[0]?.isFile;
        setIsFile(_isFile);
        setCurrentType(_data.value);
    }

    const sendData = useCallback(async () => {

        let objData = {
            id: userId || user?.id,
            type: currentType,
            review: userId === user?.id ? 0 : 1,
            create_by: user?.id
        }

        if(userId !== user?.id){
            objData.aproved = 1;
            objData.date_review = new Date();
            objData.user_review = user?.id;
        }

        const list = [];
        let current = startDate;
        console.log('Start', current.toLocaleDateString());
        let run = true;
        let c = 0;

        do{
            objData.datetime = current;
            current.setDate(current.getDate() + 1);
            console.log(current.toLocaleDateString() , endDate.toLocaleDateString(), current.toLocaleDateString() === endDate.toLocaleDateString());
            //list.push(api.post(URL_REGISTER, objData , false));
            console.log('run', !(current.toLocaleDateString() === endDate.toLocaleDateString()))
            run = c < 100;
            c++;
        }while(run);

        //const result = await api.post(URL_REGISTER, objData , false);

        await Promise.all(list);


    }, [currentType, startDate, endDate, user])

    useState(()=> {
        console.log(isFile);
    }, [currentType, isFile])

    return (
        <div className="component-check-out">
            <div className="grid">
                <div className="col-12">
                    <div className="mr-2 mb-2"><strong>Motivo:</strong></div>
                    <Dropdown style={{width:'100%'}} options={types} value={currentType} onChange={(e) => { selectType(e)}} placeholder="Selecionar motivo"/>
                </div>
                {
                    isFile ?
                        <div className="col-12">
                            <div className="mr-2 mb-2"><strong>Adicionar arquivo:</strong></div>
                            <FileUpload
                                uploadOptions={{icon: 'pi pi-fw pi-file', iconOnly: true, style:{display:'none'}}}
                                chooseOptions={{label:'Selecione o arquivo', iconOnly: true}}
                                cancelOptions={{label:'Cancelar', iconOnly: true, style:{display:'none'}, className:"p-button-danger"}}
                                customUpload 
                                accept="image/*,.doc, .docx,.pdf"
                                uploadHandler={()=>{}}  
                                emptyTemplate={()=> {
                                    return <div className="flex align-items-center flex-column">
                                                <i className="pi pi-image" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                                                <span style={{'fontSize': '1em', color: 'var(--text-color-secondary)'}} className="my-5">Arraste e solte seu arquivo aqui</span>
                                            </div>
                                }}
                            />
                        </div>
                    :
                        ''
                }
                {
                    currentType ?
                <>
                    <div className="col-12">
                        <Checkbox inputId="all_day" onChange={(e)=> setAllDay(e.checked)} checked={allDay} /> <label htmlFor="all_day" >Dia todo?</label>
                    </div>
                    <div className="col-6">
                        <div className="mr-2 mb-2"><strong>Início:</strong></div>
                        <Calendar  style={{width:'calc(100% - 90px)'}} id="date" dateFormat="dd/mm/yy" showTime={!allDay} value={startDate} onChange={(e)=> setStartDate(e.value)} locale="pt-br" />
                        <Button onClick={()=> setStartDate(new Date())} className="ml-2" label="Hoje" />
                    </div>
                    <div className="col-6">
                        <div className="mr-2 mb-2"><strong>Fim:</strong></div>
                        <Calendar style={{width:'calc(100% - 90px)'}} id="date" dateFormat="dd/mm/yy" showTime={!allDay} value={endDate} onChange={(e)=> setEndDate(e.value)} locale="pt-br" />
                        <Button onClick={()=> setEndDate(new Date())} className="ml-2" label="Hoje"  />
                    </div>
                </>
                :
                ''
                }
                <div className="col-12 mt-7" style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button onClick={sendData} className="p-button-sm" label="Registrar" />
                </div>
            </div>
        </div>
    )
}