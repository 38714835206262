import { NavigationRoute } from "./components/navigationroute";


function App() {
  return (
    <div className="App">
        <NavigationRoute />
    </div>
  );
}

export default App;
