import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';

import { localeData } from '@/services/utils';
import { URL_BIRTHDAYS_LIST, api } from '@/services/provider/api';

export const BirthdaysList = () => {

    const [current, setCurrent] = useState(new Date().getMonth());
    const [list, setList] = useState();


    const next = () => {

        let month = current + 1;
        month = month > localeData.br.monthNames.length - 1 ? 0 : month;
        setCurrent(month);
    }

    const prev = () => {

        let month = current - 1;
        month = month < 0 ? localeData.br.monthNames.length - 1 : month;
        setCurrent(month);
    }

    const getData = useCallback(async (_current) => {

        const data = await api.get(`${URL_BIRTHDAYS_LIST}/${_current + 1}`, null, true);
        setList(data.data);
        console.log('BIRTHDAYS', data.data);

    }, [setList])

    useEffect(()=> {
        getData(current);
    }, [current]);

    return (
        <div className="component-birthdays-list">
            <header className='mb-4'>
                <div className="grid">
                    <div className="col-2"></div>
                    <div className="col-2" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Button onClick={prev} icon="pi pi-arrow-left" className='p-button-info p-button-sm' />
                    </div>
                    <div className="col-4" style={{display:'flex', alignItems:'center', justifyContent:'center', fontSize:'1.5em', fontWeight:'700'}}>
                        {localeData.br.monthNames[current]}
                    </div>
                    <div className="col-2" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Button onClick={next} icon="pi pi-arrow-right" className='p-button-info p-button-sm'  />
                    </div>
                    <div className="col-2"></div>
                </div>
            </header>
            <section className='mb-4'>
                <DataTable size='small' stripedRows value={list} emptyMessage='Não há aniversáriantes'>
                    <Column body={(row)=> {
                        return new Date().getDate() === ~~row.day && new Date().getMonth() === current ? <Badge severity="success" value={row.day}></Badge> : row.day
                    }} field="day" header="Dia"></Column>
                    <Column field="name" header="Colaborador"></Column>
                </DataTable>
            </section>
        </div>
    )
}