import React, { useState } from "react";

import { Dialog } from 'primereact/dialog';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { Tag } from 'primereact/tag';
 
import { useAPP } from "@/services/provider/app";
 
import '@/assets/style/component/journey/index.scss';

export const Journey = (props) => {

    const { user } = useAPP();

    const [showResume, setShowResume] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);

    const firstDay = new Date(~~props?.date.getFullYear(), ~~props?.date.getMonth(), 1).getDate();
    const lastDay =  new Date(~~props?.date.getFullYear(), ~~props?.date.getMonth()+ 1, 0).getDate();

    const list = [];

    for(let i=firstDay; i <= lastDay; i++){

        const key = (new Date(~~props?.date.getFullYear(), ~~props?.date.getMonth(), i)).toLocaleDateString().split('/').reverse().join('_');

        const datatemp = {
            date: (new Date(~~props?.date.getFullYear(), ~~props?.date.getMonth(), i)).toLocaleDateString(),
            work: null,
            positive: null,
            negative: null,
            registers: null
        };
        
        if(props?.journeyData){
            const temp = (props?.journeyData[key] || {});
            datatemp.work = temp?.work;
            datatemp.positive = temp?.positive;
            datatemp.negative = temp?.negative;
            datatemp.registers = temp?.register;
        }
        
        list.push(datatemp);
    }

    const calculateTotalList = () => {
        let total = 0;

        list?.map(item =>{
            let week = (new Date(item.date.split('/').reverse().join('-'))).getDay();
            week = week >= 0 && week < 5 ? user?.journey : null;

            if(week){
                const [_h, _m] = week.replace(/-/gi, '').split(':');

                total += ~~_h * 60;
                total += ~~_m;
                
            }
            
            return item;
        })

        const h = Math.floor(total / 60);
        const m = total % 60;

        const _total = `${h < 10 ? '0'+h : h }:${m < 10 ? '0'+m : m}`;

        return _total;
    }

    const calculateTotalWorkList = () => {
        let totalwork = 0;
        let total = 0;

        list?.map(item =>{

            if(item.positive || item.negative){
                const [_h, _m] = (item.positive || item.negative).split(':');
                const isSum = item.positive ? true : false;

                total += (~~_h * 60) * (isSum ? 1 : -1);
                total += (~~_m) * (isSum ? 1 : -1);
                
            }

            if(item.work){
                const [_hw, _mw] = item.work?.split(':');

                if(item.work){
                    totalwork += ~~_hw * 60;
                    totalwork += ~~_mw;
                }
            }
            
            return item;
        })

        const isPositive = total < 0 ? false : true;

        total = Math.abs(total);

        const h = Math.floor(total / 60);
        const m = total % 60;

        const hw = Math.floor(totalwork / 60);
        const mw = totalwork % 60;

        const _total = `${h < 10 ? '0'+h : h }:${m < 10 ? '0'+m : m}`;
        const _totalw = `${hw < 10 ? '0'+hw : hw }:${mw < 10 ? '0'+mw : mw}`;

        return `${_totalw}  ( ${isPositive ? '+' : '-'}${_total} )`;
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <DataTable size="small" stripedRows emptyMessage="Não há registros" value={data?.registers} responsiveLayout="scroll">
                    <Column style={{width:"120px"}} field="time" header="Horário"></Column>
                    <Column field="note" header="Nota"></Column>
                </DataTable>
            </div>
        );
    }


    return(
        <div className="component-journey">
            <div className="area-date">
                <div className="box">
                    <div className="title">
                            Jornada
                    </div>
                    <div className="month">
                        {props?.month}
                    </div>
                    <div className="year">
                        {props?.year}
                    </div>
                </div>
            </div>
            <div className="area-bar">
                <div className="progress-bar">
                    {props?.positive ? <div className="bar-positive" value={~~props?.positive} style={{"--percent": `${~~props?.positive}%`}}></div> : '' }
                    {props?.negative ? <div className="bar-negative" value={~~props?.negative} style={{"--percent": `${~~props?.negative}%`}}></div> : '' }
                    {props?.extra ? <div className="bar-extra" value={~~props?.extra} style={{"--percent": `${~~props?.extra}%`}}></div> : '' }
                </div>
            </div>
            <div className="area-info">
                <div className="area-buttons">
                    <div onClick={()=>setShowResume(true)}>
                        <i className="pi pi-list mr-2" /> Detalhes
                    </div>
                    {/* <div>
                        <i className="pi pi-print mr-2" /> Exportar
                    </div> */}
                </div>
                <div className="area-text">
                <i className="pi pi-calendar" /> {~~props?.daysworks} / {~~props?.daysutils}  <i className="pi pi-clock ml-4" /> {props?.current} / {props?.total}
                </div>
            </div>
            <Dialog data="resume-journey"  header={`Resumo Jornada: ${props?.month} - ${props?.year}`} visible={showResume} style={{ width: '90%', maxWidth:'1000px' }} footer={()=><></>} onHide={() => setShowResume(false)}>
                <DataTable
                    
                    stripedRows
                    size="small" 
                    value={list}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    expandedRows={expandedRows}
                    footerColumnGroup={
                        <ColumnGroup>
                                <Row>
                                    <Column colSpan={3} footer="Totais:" footerStyle={{textAlign: 'right'}}/>
                                    <Column footer={calculateTotalList()} />
                                    <Column footer={calculateTotalWorkList()} />
                                </Row>
                            </ColumnGroup>
                    }
                    >
                    <Column style={{width:'100px'}} field="date" header="Data" />
                    <Column style={{width:'100px'}} body={(row)=> { 
                       return <>{row.registers?.length > 0 ? row.registers[0].time.substr(0, 5) : '-'}</>
                    } } header="Entrada" />
                    <Column style={{width:'100px'}} body={(row)=> { 
                       return <>{row.registers?.length > 1 ? row.registers.at(-1).time.substr(0, 5) : '-'}</>
                    } } header="Saída" />
                    <Column style={{width:'100px'}} body={(row)=> { 
                       const week = (new Date(row.date.split('/').reverse().join('-'))).getDay();
                       return week >= 0 && week < 5 ? user?.journey : '-'
                    } } header="Jornada" />
                    <Column style={{width:'180px'}} body={(row)=>{ return (<>
                        {
                            row?.work ? 
                                <>{ row.work }  { row?.positive || row?.negative ? <Tag className="ml-4" value={row?.positive || row?.negative} severity={row?.positive ? 'success' : 'danger'} icon={row?.positive ? 'pi pi-arrow-up' : 'pi pi-arrow-down'}  /> : '' }</> 
                            : 
                                ' - ' 
                        }
                        </>)
                    }} 
                    header="Executada" />
                    {/* <Column expander style={{ width: '50px' }} /> */}
                </DataTable>
            </Dialog>
        </div>
    )
}