import React, { useState, useEffect, useCallback, useMemo, useRef} from "react";

import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import { ConfirmDialog } from 'primereact/confirmdialog';
//import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { BreadCrumb } from 'primereact/breadcrumb';

import { useForm, Controller } from 'react-hook-form';
 
import { useNavigate, useParams } from "react-router-dom";
import { api, URL_FEEDBACK_ALLQUESTIONSBYFEEDBACK, URL_FEEDBACK_SENDANSWERSBYQUIZ, URL_FEEDBACK_DETAILS_BY_USER } from "@/services/provider/api";

import { useAPP } from "@/services/provider/app";

import '@/assets/style/pages/feedback/answer/index.scss';

export const PageFeedbackAnswer = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState();

    const { user, setIsLoading } = useAPP();

    const answerList = useRef({});

    const defaultValues = useMemo(()=>({
        
    }), []);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);

    const updateAnswers = (_data) => {

        if(!answerList.current[`question_${_data.order}`]){
            answerList.current[`question_${_data.order}`] = {};
        }

        if(answerList.current[`question_${_data.order}`]){
            answerList.current[`question_${_data.order}`].lastupdate = `${(new Date()).toISOString().substring(0, 10)} ${(new Date()).toISOString().substring(11, 19)}`;
            answerList.current[`question_${_data.order}`].question_text = _data?.question;
            answerList.current[`question_${_data.order}`].order = _data?.order;
            answerList.current[`question_${_data.order}`].question_id = _data?.id;
        }
    }

    const getContent = (_data) => {

        const types = {
            star:()=>{
                return <div key={`order-${_data.order}`} className={`question ${_data.type}`}>
                        <div className="ask">
                            <div className="number">
                                {_data?.order}
                            </div>
                            <div className="text">
                                {_data?.question}
                            </div>
                        </div>
                        <div className="aswers">
                            <Controller name={`question_${_data.order}`} control={control} render={({ field }) => (
                                <Rating {...field} disabled={~~answers?.aswers_user_total === ~~answers?.total_questions}  onChange={(e)=>{ updateAnswers(_data); field.onChange(e.target.value);}} value={field.value}  cancel={false} />
                            )} />
                        </div>
                    </div>
            },
            text:()=>{
                return <div key={`order-${_data.order}`} className={`question ${_data.type}`}>
                        <div className="ask">
                            <div className="number">
                                {_data?.order}
                            </div>
                            <div className="text">
                                {_data?.question}
                            </div>
                        </div>
                        <div className="aswers">
                            <Controller name={`question_${_data.order}`} control={control} render={({ field }) => (
                                <InputTextarea {...field} disabled={~~answers?.aswers_user_total === ~~answers?.total_questions} inputStyle={{width:'100%'}} style={{width:'100%'}} autoResize {...field}  onChange={(e)=>{updateAnswers(_data); field.onChange(e.target.value);}} value={field.value} />
                            )} />
                        </div>
                    </div>
            }
        }

        if(types[_data?.type])
            return types[_data?.type]();

        return '';

    }

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_FEEDBACK_ALLQUESTIONSBYFEEDBACK}/${params.id}`, null, true);

        data?.data?.map(item => {
            answerList.current[`question_${item.order}`] = null;
            return item;
        })


        const dataanswers = await api.get(`${URL_FEEDBACK_DETAILS_BY_USER}/${params.id}/${user?.id}`, null, true);


        if(~~dataanswers.data?.aswers_user_total === ~~dataanswers.data?.total_questions){
            dataanswers.data?.answers?.map(item => {
                answerList.current[`question_${item.question_order}`] = item.answer_user;
                return item;
            })
        }

        
        setAnswers(dataanswers.data);
        setQuestions(data.data);
        reset(answerList.current);

    }, [params, reset, setQuestions, setAnswers, user]);

    const onSubmit = useCallback(async (_data) => {
        const isErros = [];
        Object.keys(_data).map(item =>{

            if(!_data[item]){
                isErros.push(item);
            }else if(!`${_data[item]}`.toString().replace(/^\s*|\s*$/gi,'')){
                isErros.push(item);
            }

            return item;
        })

        if(isErros.length > 0){
            toast.current.show({severity:'error', summary: 'Erro', detail: 'Responda todas as perguntas', life: 3000});
        }else{
            setIsLoading(true);
            const listanswers = [];
            Object.keys(_data).map(item =>{
                
                const answertosend = {
                    feedback_id: params.id,
                    question_text: answerList.current[item]?.question_text,
                    question_order: answerList.current[item]?.order,
                    feedback_question_id: answerList.current[item]?.question_id,
                    time_answer_user: answerList.current[item]?.lastupdate,
                    answer_user: _data[item],
                    user_id: user.id
                }

                listanswers.push(api.post(URL_FEEDBACK_SENDANSWERSBYQUIZ, answertosend, true));

                return item;
            })

            const result =  await Promise.all(listanswers);

            await getData();

            setIsLoading(false);
            if(result){
                toast.current.show({severity:'success', summary: 'Sucesso', detail: 'Dados atualizados com sucesso', life: 3000});
            }

        }

        // console.log('Erros', isErros);
        // console.log(_data);
    }, [getData, user, params, setIsLoading]);

    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-feedback-answer">
            <BreadCrumb 
                className='mt-2 mb-4'
                model={[
                    { label: 'Feedback', command:()=> navigate(`/user/feedback`) }
                ]} 
                home={{ icon: 'pi pi-home', command:()=> navigate('/user') }}
            />

            {
                ~~answers?.aswers_user_total === ~~answers?.total_questions ?
                    <Message style={{width:'100%'}} severity="success" text="Finalizado com sucesso" />
                :
                    ''
            }

            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-star-fill mr-2"></i>
                    <b>Feedback - Respostas</b>
                </div>
            </Divider>
            <div className="grid">
                <div className="col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {
                            questions?.map((item)=>{
                                return  getContent(item);
                            })
                        }
                        <div className="grid mt-6">
                            <div className="col-12" style={{textAlign:'center'}}>
                                {
                                    ~~answers?.aswers_user_total !== ~~answers?.total_questions ?
                                        <Button label="Enviar" />
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ConfirmDialog />
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}