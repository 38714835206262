import React, { useCallback, useMemo, useEffect, useRef } from "react";

import { Clock } from "@/components/clock";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';

import { useAPP } from "@/services/provider/app";
import { api, URL_REGISTER } from "@/services/provider/api";

import { useNavigate } from "react-router-dom";

import  logo from '@/assets/image/logo.png';

import '@/assets/style/pages/register/index.scss';

export const PageRegister = () => {


    const navigate = useNavigate();

    const { setIsLoading } = useAPP();

    const defaultValues = useMemo(()=>({
        login: '',
        password: ''
    }), []);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);

    const onSubmit = useCallback(async (data) => {
        setIsLoading(true);
        const result = await api.post(URL_REGISTER, data , false);
        //console.log('Result', result.data);
        setIsLoading(false);

        if(~~result.data.status === 200){
            toast.current.show({severity:'success', summary: 'Sucesso', detail: result.data.message, life: 3000});
            reset(defaultValues);
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
        }

    }, [defaultValues, reset, setIsLoading]);

    useEffect(()=> {
        setIsLoading(false);

    }, [setIsLoading]);

    return(
        <div className="page-register">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="area-box">
                <Clock />
                <div className="box">
                    <header>Registro de presença</header>
                    <section>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid">
                                <div className="col-12 mt-4">
                                <Controller name="login" control={control} render={({ field }) => (
                                    <InputText {...field} id={field.name} placeholder="Informe sua matricula" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                )} />
                                </div>

                                <div className="col-12 mt-2"> 
                                    <Controller name="password" control={control} render={({ field }) => (
                                        <Password {...field} id={field.name} placeholder="Informe sua senha" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} feedback={false} inputStyle={{width: '100%'}} style={{width: '100%'}} toggleMask />
                                    )} />
                                </div>

                                <div className="col-12 mt-2" style={{textAlign:'right'}}> 
                                    <Button label="Registrar presença" className="p-button-info" />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            <div className="header">
                <span onClick={()=>navigate('/login')} style={{cursor:'pointer'}}>
                    <i className="pi pi-lock"/><span className="ml-2">Acessar portal</span>
                </span>
            </div>
            <div className="footer">
                Versão: {process.env.REACT_APP_VERSION}
            </div>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}