import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { AppProvider } from '@/services/provider/app';

import '@/assets/style/index.scss';
import { UILoading } from './components/ui/loading';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <AppProvider>
        <App />
        <UILoading />
    </AppProvider>
  </React.StrictMode>
);
