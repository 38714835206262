export const localeData = {
    br:{
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        today: 'Hoje',
        clear: 'Limpar'
    }
}

export const calculateDuration = (_start, _end) => {

    if(!_start || !_end){
        return '-';
    }

    const start = new Date(_start);
    const end = new Date(_end);

    const ms = end - start;

    const s = Math.round(( ms / 1000 ) % 60);  
    const m = Math.round((ms / 60000 ) % 60); 
    const h = Math.round((ms / 3600000 ) % 24);
    const d = Math.round(ms / 86400000);

    return {
        days: d < 10 ? `0${d}` : d,
        hours: h < 10 ? `0${h}` : h,
        minutes: m < 10 ? `0${m}` : m,
        seconds: s < 10 ? `0${s}` : s
    };

}

export const secondsToTime = (_sec) =>{
    return new Date(_sec * 1000).toISOString().substring(11, 16);
}

export const timeToSeconds = (_time) =>{
    if(!_time)
        return '0';
    
    let [h, m, s] = _time?.split(':');
    let sec = (~~h * 3600) + (~~m  * 60);

    return sec;
}