import React from "react";

import { useAPP } from "@/services/provider/app";

import  logo from '@/assets/image/logo.png';

import '@/assets/style/component/ui/loading/index.scss';

export const UILoading = () => {

    const { isLoading } = useAPP();

    return(
        <div className={`ui-loading ${isLoading ? 'show' : 'hidden'}`}>
            <img src={logo} alt="" />
        </div>
    )
}