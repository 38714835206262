import React, { useCallback, useEffect, useState } from "react";

import { Avatar } from 'primereact/avatar';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { Badge } from 'primereact/badge';
 
 

import { useAPP } from "@/services/provider/app";
import { Outlet, useNavigate } from "react-router-dom";

import  logo from '@/assets/image/logo.png';

import { api, URL_LOGOUT, URL_USER_PROFILE } from "@/services/provider/api";
import { setLogout } from "@/services/provider/auth";

import '@/assets/style/pages/user/index.scss';

export const PageUser = () => {

    const { setIsLoading, user, setUser } = useAPP();

    const [showHelp, setShowHelp] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationData] = useState(0);

    const navigate  = useNavigate();


    const menu = () => {
        let _menu = [];

        // _menu = [
        //     {name:'Home', icon:'pi pi-home', action: '/user'},
        //     {name:'Histórico', icon:'pi pi-history', action: '/user/history'},
        //     {name:'Registrar', icon:'pi pi-calendar', action: '/user/register'},
        //     {name:'Colaboradores', icon:'pi pi-users', action: '/user/collaborators'}
        // ]

         user?.access?.map(item => {

            if(item.menu_name){
                _menu.push({name: item.menu_name, icon:item.icon, action: item.url.length === 0  ? '/user' : `/user/${item.url}`});
            }

            return item
        })

        return _menu.map((item, id) => {
            return <div key={`menu-item-${id}`} 
                        tooltip={item.name} 
                        onClick={()=>{navigate(item.action);}}>
                <i className={item.icon} />
            </div>;
        })
    }

    const executeLogout = useCallback(async () => {
        setUser(null);
        await api.post(URL_LOGOUT, null, true);
        setLogout();
        navigate('../login');
    }, [navigate, setUser])

    const getUserData = useCallback(async () => {

        if(!user){
            const userdata = await api.get(URL_USER_PROFILE, null, true);
            setUser(userdata.data);
        }

    }, [setUser, user])

    useEffect(()=>{
        getUserData();
        setIsLoading(false);
    }, [setIsLoading, getUserData])

    return (
        <div className="page-user">
            <header>
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <div className="area-menu">
                    {menu()}
                    <div 
                        tooltip="Logout" 
                        onClick={executeLogout}>
                        <i className='pi pi-power-off' />
                    </div>

                </div>
                <div className="area-help">
                    <Button onClick={()=>setShowHelp(true)} icon="pi pi-cog" className="p-button-rounded p-button-sm p-button-warning" />
                </div>
            </header>
            <section>
                <header>
                    <div className="name">
                        <strong>{user?.name}</strong>
                    </div>
                    <div className="area-user">
                        <i onClick={()=>setShowNotification(true)} icon="pi pi-bell" className="pi pi-bell  p-overlay-badge mr-4" style={{fontSize:'1em', cursor:'pointer'}} >
                            {
                                ~~notificationData > 0 ?
                                    <Badge severity="danger" value={~~notificationData}></Badge>
                                :
                                    ''
                            }
                        </i>
                        <Avatar onClick={()=>navigate('/user/profile')} style={{cursor:'pointer'}} shape="circle" icon="pi pi-user" />
                    </div>
                </header>
                <div className="content">
                    <Outlet/>
                </div>
            </section>
            <Dialog header="Sistema" position="bottom" visible={showHelp} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowHelp(false)}>
                <div className="grid">
                    <div className="col-12">
                        <strong>App</strong>: {process.env.REACT_APP_NAME} - {process.env.REACT_APP_VERSION}
                    </div>
                </div>
            </Dialog>
            <Sidebar position="right" visible={showNotification} onHide={() => setShowNotification(false)}></Sidebar>
        </div>
    )
}