import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';

import { Divider } from 'primereact/divider';
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
 

import { api, URL_USER_COLABORATORS, URL_USER_UPDATE, URL_USER_CREATE } from '@/services/provider/api';

export const PageCollaborators = () => {

    const navigate = useNavigate();

    const [collaborators, setCollaborators] = useState();
    const [selectedCollaborators, setSelectedCollaborators] = useState();
    const [showAdd, setShowAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(()=>({
        enrol_id: '',
        name: '',
        email:'',
        journey: new Date(`${new Date().toISOString().substr(0, 10)} 08:48:00`)

    }), []);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);
    const selectedList = useRef([]);

    const getData = useCallback(async () => {

        const data = await api.get(URL_USER_COLABORATORS, null, true);
        setCollaborators(data?.data);

    }, [setCollaborators])

    const onSubmit = useCallback(async (data) => {

       // data.journey = data.journey.toLocaleTimeString().substr(0, 5);
       delete data.journey;
       data.register_scales_journey_id = 1;
       const [d, m, y] = data.birthday.toLocaleDateString().split('/');
       data.birthday = `${d}-${m}`;

        setIsLoading(true);
        const result = await api.post(URL_USER_CREATE, data , true);
        setIsLoading(false);

        if(~~result?.data?.status === 200){
            toast.current.show({severity:'success', summary: 'Sucesso', detail: result?.data?.message, life: 3000});
            reset(defaultValues);

            getData();
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail: result?.data?.message, life: 3000});
        }

    }, [defaultValues, reset, getData]);

    const alterActiveUser = useCallback(async (_data) => {

        const accept = async () => {
            
            const result = await api.put(`${URL_USER_UPDATE}/${_data.id}`, {active: ~~_data.active === 1 ? 0 : 1} , true);
            
            if(result.data){
                toast.current.show({severity:'success', summary: 'Sucesso', detail: 'Dados atualizados com sucesso', life: 3000});                
            }else{
                toast.current.show({severity:'error', summary: 'Erro', detail:'Houve um erro, tente novamente', life: 3000});
            }

            getData();
        }

        confirmDialog({
            message: ~~_data.active === 1 ? `Deseja desativar o usuario: ${_data.name}?` : `Deseja ativar o usuario: ${_data.name}?`,
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: accept,
            reject: () =>{}
        });

    }, [getData])

    const alterAllUsers = useCallback(async (_active) => {

        const accept = async () => {
            
            const list = selectedList.current.map(item => {
                return api.put(`${URL_USER_UPDATE}/${item.id}`, {active: _active ? 1 : 0} , true);
            })

            await Promise.all(list);

            toast.current.show({severity:'success', summary: 'Sucesso', detail: 'Dados atualizados com sucesso', life: 3000});

            getData();

        }

        confirmDialog({
            message: `Deseja alterar os status dos usuarios selecionados?`,
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: accept,
            reject: () =>{}
        });

    }, [getData])

    useEffect(()=>{
        selectedList.current = selectedCollaborators;
    }, [selectedCollaborators]);

    useEffect(()=>{
        reset(defaultValues);
    }, [showAdd, reset, defaultValues])

    useEffect(()=>{

        getData();

    }, [getData]);

    return (
        <div className="page-collaborators">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-users mr-2"></i>
                    <b>Colaboradores</b>
                </div>
            </Divider>
            <div className="grid">
                <div className="col-12">
                    <Toolbar left={()=>{
                        return <>
                        </>
                    }} 
                    right={()=>{
                        return <>
                            <Button onClick={()=>setShowAdd(true)} className='p-button-sm p-button-info mr-2' icon="pi pi-user-plus" label="Criar novo colaborador" />
                        </>
                    }} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable 
                        header={()=>{
                            return <div style={{textAlign:'right'}}>
                                <Button onClick={getData} label="Sincronizar" icon="pi pi-sync" className='p-button-sm p-button-secondary mr-2' style={{padding:'10px', width:'auto', height:'32px'}} />
                                <Button onClick={()=>alterAllUsers(true)} disabled={selectedCollaborators?.length > 0 ? false : true} label="Ativar selecionados" icon="pi pi-eye" className='p-button-sm p-button-success mr-2' style={{padding:'10px', width:'auto', height:'32px'}} />
                                <Button onClick={()=>alterAllUsers(false)} disabled={selectedCollaborators?.length > 0 ? false : true} label="Desativar selecionados" icon="pi pi-eye-slash" className='p-button-sm p-button-danger' style={{padding:'10px', width:'auto', height:'32px'}} />
                            </div>
                        }}
                        selection={selectedCollaborators} 
                        onSelectionChange={(e) => setSelectedCollaborators(e.value)}
                        datakey="id"
                        size="small" paginator={collaborators?.length > 10} rows={10} value={collaborators} emptyMessage="Não há registros" >

                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false} />
                        <Column style={{width:'70px'}} body={(row)=>{
                                return <Avatar className='ml-2' icon="pi pi-user" shape='circle' />
                        }} header="Avatar"  />
                        <Column style={{width:'120px'}} field="enrol_id" header="Matricula" />
                        <Column field="name" header="Nome" />
                        <Column style={{width:'70px', textAlign:'center'}} field="journey" header="Jornada" />
                        <Column style={{width:'70px'}} body={(row)=>{
                            return <Tag style={{width:'100%'}} value={~~row.active === 1 ? 'Ativo' : 'Inativo'} severity={~~row.active === 1 ? 'success' : 'danger'}  />
                        }} header="Status" />
                        <Column style={{width:'120px'}} body={(row)=>{
                           return <>
                                <Button onClick={()=>navigate(`/user/collaborators/${row.id}`)} className='p-button-sm p-button-info p-button-text' icon="pi pi-id-card" tooltip='Perfil' tooltipOptions={{position:'bottom'}}/>
                                <Button onClick={()=>alterActiveUser(row)} className='p-button-sm p-button-danger p-button-text' icon={`pi ${ ~~row.active === 1 ? 'pi-eye-slash' : 'pi-eye'}`} tooltip={`${ ~~row.active === 1 ? 'Desativar' : 'Ativar'}`} tooltipOptions={{position:'bottom'}}/>
                            </>
                        }} header="Ações" />
                    </DataTable>
                </div>
            </div>
            <Dialog header={`Adicionar colaborador`} visible={showAdd} style={{ width: '90%', maxWidth:'600px' }} footer={()=><></>} onHide={() => setShowAdd(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12 mt-4">
                            <Controller name="name" control={control} render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText {...field} id={field.name} placeholder="Informe o nome" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2">
                            <Controller name="enrol_id" control={control} render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <InputText {...field} id={field.name} placeholder="Informe a matricula" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2">
                            <Controller name="email" control={control} render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-at"></i>
                                    </span>
                                    <InputText {...field} id={field.name} placeholder="Informe o e-mail" type="email" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2">
                            <Controller name="journey" control={control}  render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-clock"></i>
                                    </span>
                                    <Calendar disabled timeOnly {...field} id={field.name} placeholder="Informe a jornada" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2">
                            <Controller name="birthday" control={control}  render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <Calendar {...field} id={field.name} placeholder="Informe a data de nascimento" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2">
                            <Controller name="password" control={control}  render={({ field }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-eye-slash"></i>
                                    </span>
                                    <InputText type='password' {...field} id={field.name} placeholder="Informe a senha" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                </div>
                            )} />
                        </div>
                        <div className="col-12 mt-2" style={{textAlign:'right'}}> 
                            <Button loading={isLoading} label="Cadastrar" className="p-button-info" />
                        </div>
                    </div>
                </form>
            </Dialog>
            <ConfirmDialog />
            
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}