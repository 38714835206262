import { useCallback, useEffect, useState } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';

import { URL_USER_BANK_DATA, api } from "@/services/provider/api"
import { secondsToTime, timeToSeconds } from "@/services/utils";

export const PageBank = ({user = null}) => {

    const [bank, setBank] = useState();

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_USER_BANK_DATA}/${user}`, null, true);
        console.log('Bank', data.data);
        setBank(data.data);
    },[])

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="page-bank">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-calendar  mr-2"></i>
                    <b>Banco de horas</b>
                </div>
            </Divider>
            <div className="grid mt-2 mb-2">
                <div className="col-6">
                    <div className="card" style={{'--bg':'var(--blue-200)', '--text':'var(--blue-800)'}}>
                        <div className="title">Total geral</div>
                        <div className="icon">
                            <i className="pi pi-clock" />
                        </div>
                        <div className="content">
                            {
                                bank ?
                                   secondsToTime(bank.reduce((a, n) =>{ 
                                        return a + timeToSeconds(n.total);
                                    }, 0)
                                   )
                                :
                                    '-'
                            }
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card" style={{'--bg':'var(--red-200)', '--text':'var(--red-800)'}}>
                        <div className="title">Erros</div>
                        <div className="icon">
                            <i className="pi pi-times" />
                        </div>
                        <div className="content">
                            {
                                bank ?
                                    bank.reduce((a, n) => a + ~~n.erros, 0)
                                :
                                    '-'
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable
                        value={bank}
                        size="small"
                        stripedRows
                        loading={!bank}
                    >
                        <Column field="date" header='Periodo' body={(row) => (row.date.split('-').reverse()).join('/')} />
                        <Column field="positive" header='Positivo'  />
                        <Column field="negative" header='Negativo'  />
                        <Column field="total" header='Total'  />
                        <Column field="erros" header='Erros'  />
                    </DataTable>
                </div>
            </div>
        </div>

    )
}
