import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";

import { Divider } from 'primereact/divider';
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tag } from 'primereact/tag';

import { useNavigate, useParams } from "react-router-dom";
import { api, URL_FEEDBACK_DETAILS } from "@/services/provider/api";

export const PageFeedbackView = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [content, setContent] = useState();
    const [updateIndex, setUpdateIndex] = useState((new Date()).getTime());

    const dataFeedback =  useRef();

    const items = useMemo(()=>[
        {label:'Respostas'},
        {label:'Inscritos'},
        {label:'Perguntas'}
    ], [])

    const getContent = useCallback((idx)=>{

        let contentData = '';

        switch(idx){
            case 0:
                contentData = (
                    <DataTable size="small" value={dataFeedback.current?.answers} emptyMessage="Não há registros" >
                        <Column style={{width:'100px'}} body={(row)=>{
                            return <Tag style={{width:'100%'}} value={ row.status} severity={(/complete/gi).test(row.status) ? 'success' : 'warning'  }  />
                        }} field="status" header="Status" />
                        <Column field="enrol_id" header="Matricula" />
                        <Column field="collaborator_name" header="Colaborador" />
                        <Column field="adm_name" header="Avaliador" />
                        <Column style={{width:'40px'}}  body={(row)=>{
                            return <Button onClick={()=>navigate(`/user/feedback/${params.id}/view/${row.user_id}`)} icon="pi pi-file" className="p-button-text" />
                        }} header="" />
                    </DataTable>
                )
            break;
            case 1:
                contentData = (
                    <DataTable size="small" value={dataFeedback.current?.enrol} emptyMessage="Não há registros" >
                        <Column style={{width:'200px'}} field="enrol_id" header="Matricula" />
                        <Column field="collaborator_name" header="Colaborador" />
                    </DataTable>
                )
            break;
            case 2:
                contentData = (
                    <DataTable rowGroupMode="rowspan" groupRowsBy="direction_name" size="small" value={dataFeedback.current?.questions} emptyMessage="Não há registros" >
                        <Column field="direction_name" header="Direção" />
                        <Column field="competence_hability_name" header="Competência/ Habilidade" />
                        <Column field="order" header="Ordem" />
                        <Column field="question" header="Questão" />
                    </DataTable>
                )
            break;
            default:
                contentData = '';

        }

        setContent(contentData);

    }, [setContent, navigate, params])

    const getData = useCallback(async()=>{
        const result = await api.get(`${URL_FEEDBACK_DETAILS}/${params.id}`, null, true);
        dataFeedback.current = result.data;
        console.log(result.data);
        setData(result.data);
        setUpdateIndex((new Date()).getTime());
    }, [setData, params, setUpdateIndex]);

    useEffect(()=>{

        getContent(activeIndex)

    }, [activeIndex, getContent])


    useEffect(()=>{
        getContent(activeIndex)
    },[updateIndex, getContent, activeIndex])

    useEffect(()=>{
        getData();
    },[getData])

    return (
        <div className="page-feedback-view">
            <BreadCrumb 
                className='mt-2 mb-4'
                model={[
                    { label: 'Feedback', command:()=> navigate(`/user/feedback`) }
                ]} 
                home={{ icon: 'pi pi-home', command:()=> navigate('/user') }}
            />
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-star mr-2"></i>
                    <b>Feedback - Detalhes</b>
                </div>
            </Divider>
            <div className="grid mt-4 mb-4">
                <div className="col-12">
                    <strong>Nome</strong>: {data?.name || '-'}
                </div>
                <div className="col-6">
                    <strong>Início</strong>: { data?.create ? `${(new Date(data?.create)).toLocaleDateString()} - ${(new Date(data?.create)).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-6">
                    <strong>Fim</strong>: { data?.end ?  `${(new Date(data?.end)).toLocaleDateString()} - ${(new Date(data?.end)).toLocaleTimeString()}` : '-'}
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
                </div>
            </div>
            {
                data ?
                    <div key={`update-${updateIndex}`} className="grid mt-4">
                        <div className="col-12">
                            {content}
                        </div>
                    </div>
                :
                'carregando'
            }
        </div>
            
    )
}