import { getToken } from "../auth";

export const URL_BASE = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/registropresenca/api/' : '/api/';
//export const URL_BASE = 'https://rp.fiskaldigital.com.br/api/';


const requestPost = async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'POST',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;

}

const requestGet = async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'GET',
        headers: headerdata
    }

    let query = '';
    if(data){
         const _query = Object.keys(data).map(item => `${item}=${data[item]}`);
         query = `?${_query.join('&')}`;
    }

    const result = await fetch(`${url}${query}`, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestPut =  async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'PUT',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestDelete =  async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'DELETE',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestUpload =  async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'POST',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

export const api = {
    post : requestPost,
    get : requestGet,
    put : requestPut,
    delete : requestDelete,
    upload: requestUpload
}


export const URL_REGISTER = `${URL_BASE}user/register`;
export const URL_REGISTER_LIST = `${URL_BASE}user/registerlist`;
export const URL_BIRTHDAYS_LIST = `${URL_BASE}user/getBirthdays`;

export const URL_REGISTER_BY_DAY = `${URL_BASE}user/registerbyday`;
export const URL_FIRST_REGISTER_BY_DAY = `${URL_BASE}user/firstRegisterByDay`;
export const URL_LAST_REGISTER_BY_DAY = `${URL_BASE}user/lastRegisterByDay`;
export const URL_REGISTER_JOURNEY_BY_MONTH = `${URL_BASE}user/journeyByMonth`;
export const URL_REGISTER_LIST_ERROS = `${URL_BASE}user/registerWitchErros`;
export const URL_REGISTER_LIST_REVIEW = `${URL_BASE}user/registerWitchReview`;
export const URL_REGISTER_UPDATE = `${URL_BASE}user/updateRegister`;

export const URL_USER_UPDATE = `${URL_BASE}user/update`;
export const URL_USER_CREATE = `${URL_BASE}user/create`;
export const URL_USER_DELETE_CHECK = `${URL_BASE}user/deleteCheck`;
export const URL_USER_BANK_DATA = `${URL_BASE}user/bankHoursData`;


export const URL_LOGIN = `${URL_BASE}user/login`;
export const URL_LOGOUT = `${URL_BASE}user/logout`;

export const URL_USER_PROFILE = `${URL_BASE}user/profile`;

export const URL_USER_COLABORATORS = `${URL_BASE}user/collaborators`;



export const URL_FEEDBACK_ALLQUESTIONS = `${URL_BASE}feedback/allquestions`;
export const URL_FEEDBACK_ALLQUESTIONSBYFEEDBACK = `${URL_BASE}feedback/allquestionsbyfeedback`;
export const URL_FEEDBACK_ALLFEEDBACKS = `${URL_BASE}feedback/allfeedback`;
export const URL_FEEDBACK_MYFEEDBACKS = `${URL_BASE}feedback/myfeedback`;
export const URL_FEEDBACK_DETAILS = `${URL_BASE}feedback/feedbackdetails`;
export const URL_FEEDBACK_DETAILS_BY_USER = `${URL_BASE}feedback/feedbackdetailsbyuser`;
export const URL_FEEDBACK_SENDANSWERSBYQUIZ = `${URL_BASE}feedback/sendanswersbyquiz`;
export const URL_FEEDBACK_UPDATEANSWER = `${URL_BASE}feedback/updateAnswer`;





export const URL_VALIDATE_TOKEN = `${URL_BASE}session/validate`;
