import React, { useEffect, useCallback , useRef, useMemo} from 'react';

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';

import { useAPP } from '@/services/provider/app';
import { api, URL_VALIDATE_TOKEN, URL_LOGIN } from '@/services/provider/api';
import { useNavigate } from 'react-router-dom';

import  logo from '@/assets/image/logo.png';

import '@/assets/style/pages/login/index.scss';
import { setLogin } from '@/services/provider/auth';

export const PageLogin = () => {

    const {setIsLoading } = useAPP();

    const navigate = useNavigate();

    const defaultValues = useMemo(()=>({
        login: '',
        password: ''
    }), []);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    //const reset = form.reset;

    const toast = useRef(null);

    const onSubmit = useCallback(async (data) => {
        setIsLoading(true);
        const result = await api.post(URL_LOGIN, data , false);
        
        if(result.data){
            setLogin(result.data);
            navigate('/user');
        }else{
            setIsLoading(false);
            toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
        }

    }, [setIsLoading, navigate]);

    const validateToken = useCallback(async () => {
        const tokenvalid = await api.get(URL_VALIDATE_TOKEN, null, true);
        console.log(tokenvalid.data);
        setIsLoading(false);
    },[setIsLoading])

    useEffect(()=> {
        validateToken();
    }, [validateToken]);

    return (
        <div className="page-login">
            <div className="area-box">
                <div className="box">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <header>Acessar seu portal</header>
                    <section>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid">
                                <div className="col-12 mt-4">
                                <Controller name="login" control={control} render={({ field }) => (
                                    <InputText {...field} id={field.name} placeholder="Informe sua matricula/email" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} autoComplete="off" style={{width:'100%'}}  />
                                )} />
                                </div>

                                <div className="col-12 mt-2"> 
                                    <Controller name="password" control={control} render={({ field }) => (
                                        <Password {...field} id={field.name} placeholder="Informe sua senha" required onChange={(e)=>{field.onChange(e.target.value)}} value={field.value} feedback={false} inputStyle={{width: '100%'}} style={{width: '100%'}} toggleMask />
                                    )} />
                                </div>

                                <div className="col-12 mt-2" style={{textAlign:'right'}}> 
                                    <Button label="Entrar" className="p-button-info" />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}