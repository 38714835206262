import React, {useEffect, useState, useCallback, useRef} from "react";

import { Journey } from "@/components/journey";
import { JourneyDay } from "@/components/journeyday";
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { BirthdaysList } from "@/components/birthdays";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
 

import { localeData } from '@/services/utils';
import { useAPP } from "@/services/provider/app";
import { api, URL_REGISTER_JOURNEY_BY_MONTH, URL_REGISTER_LIST_ERROS } from "@/services/provider/api";

import '@/assets/style/pages/user/home/index.scss';
import { CheckTime } from "@/components/checktime";
import { CheckOut } from "@/components/checkout";

export const PageHomeUser = () => {

    const today = useRef(new Date());
    let daysUtils = useRef(0);
    
    const [positive, setPositive] = useState();
    const [negative, setNegative] = useState();
    const [extra, setExtra] = useState();
    const [current, setCurrent] = useState(0);
    const [total, setTotal] = useState(0);
    const [dayswork, setDayswork] = useState(0);
    const [daysutils, setDaysutils] = useState(0);
    const [showCheckTime, setShowCheckTime] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showOut, setShowOut] = useState(false);
    const [update, setUpdate] = useState(0);
    const [pendences, setPendences] = useState();

    const [journeyData, setJourneyData] = useState();

    const { user } = useAPP();


    const calculateDaysUtils = useCallback(async () => {
        const lastday = (new Date(today.current.getFullYear(), today.current.getMonth() + 1, 0)).getDate();
        
        let _daysUtils = 0;

        for(let i = 1, ii = lastday; i <= ii; i++){
            const temp = new Date();
            temp.setDate(i);

            if(temp.getDay() > 0  && temp.getDay() < 6)
                _daysUtils++;
        }

        daysUtils.current = _daysUtils;

    }, [daysUtils, today])

    const calculateJourneyMonth = useCallback(()=>{

        if(user?.journey && daysUtils.current){
            const [hours, minutes] = user?.journey.split(':');
            const _current = (~~hours * 60) + ~~minutes;

            const _total = daysUtils.current * _current;
            let _hours = Math.floor(_total / 60);
            let _minutes = _total % 60;

            _hours = _hours < 10 ? `0${_hours}` :_hours;
            _minutes =_minutes < 10 ? `0${_minutes}` : _minutes;

            setTotal(`${_hours}:${_minutes}`);
        }

    }, [daysUtils, user])

    const journeyMonth = useCallback(async () =>{
        const data = await api.get(URL_REGISTER_JOURNEY_BY_MONTH, null, true);
        setJourneyData(data?.data);

        if(data?.data){
            let _total = 0;
            let _positive = 0;
            let _negative = 0;
            let _extra = 0;
            let _current = 0;
            let _works = 0;

            Object.keys(data?.data).map(item => {
                if(data?.data[item].work){
                    
                    _total++;
                    _works++;

                    const [hours, minutes] = data?.data[item].work.split(':');
                    _current += (~~hours * 60) + ~~minutes;

                    if(!data?.data[item].positive && !data?.data[item].negative)
                        _positive++

                    if(data?.data[item].positive)
                        _extra++;

                    if(data?.data[item].negative)
                        _negative++;
                }
                return item;
            })

            let _hours = Math.floor(_current / 60);
            let _minutes = _current % 60;

            _hours = _hours < 10 ? `0${_hours}` :_hours;
            _minutes =_minutes < 10 ? `0${_minutes}` : _minutes;

            setCurrent(`${_hours}:${_minutes}`);

            _total = daysUtils.current;

            if(_total > 0){
                if(_positive > 0)
                    setPositive(_positive / _total * 100);
                if(_negative > 0)
                    setNegative(_negative / _total * 100);
                if(_extra > 0)
                    setExtra(_extra / _total * 100);
            }

            setDayswork(_works);
            setDaysutils(daysUtils.current);

        }

    }, [])

    const getPendences = useCallback(async () => {
        const rs = await api.get(URL_REGISTER_LIST_ERROS, null, true);

        setPendences(rs.data);
    }, [])

    const openCheckTime = () => {

        setShowCheckTime(true);
    }


    const openOut = () => {

        setShowOut(true);
    }

    
    useEffect(()=>{
        calculateDaysUtils();
        calculateJourneyMonth()
        journeyMonth();
        getPendences();
    },[calculateDaysUtils, calculateJourneyMonth, journeyMonth, update, getPendences])

    return(
        <div className="page-home-user" key={`page-update-${update}`}>
            <div className="grid">
                <div className="col-7">
                <Journey 
                        date={today.current}
                        month={localeData.br.monthNames[today.current.getMonth()]}
                        year={today.current.getFullYear()}
                        positive={positive}
                        negative={negative}
                        extra={extra}
                        current={current}
                        total={total}
                        daysworks={dayswork}
                        daysutils={daysutils}
                        journeyData={journeyData}
                    />
                    <JourneyDay />
                    <Divider className=" mt-6" type="dashed" >
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-stopwatch mr-2"></i>
                            <b>Saldo</b>
                        </div>
                    </Divider>
                    <div className="grid mt-2 mb-2">
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--green-100)', '--text':'var(--green-700)'}}>
                                <div className="title">{localeData.br.monthNames[today.current.getMonth() - 1]} positivo</div>
                                <div className="icon">
                                    <i className="pi pi-arrow-up" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--red-100)', '--text':'var(--red-700)'}}>
                                <div className="title">{localeData.br.monthNames[today.current.getMonth() - 1]} negativo</div>
                                <div className="icon">
                                    <i className="pi pi-arrow-down" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--yellow-100)', '--text':'var(--yellow-700)'}}>
                                <div className="title">Total {localeData.br.monthNames[today.current.getMonth() - 1]}</div>
                                <div className="icon">
                                    <i className="pi pi-clock" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card" style={{'--bg':'var(--blue-200)', '--text':'var(--blue-800)'}}>
                                <div className="title">Banco de horas</div>
                                <div className="icon">
                                    <i className="pi pi-calendar" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-4">
                            <div className="card" style={{'--bg':'var(--red-200)', '--text':'var(--red-800)'}}>
                                <div className="title">Total negativo</div>
                                <div className="icon">
                                    <i className="pi pi-arrow-down" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--blue-200)', '--text':'var(--blue-800)'}}>
                                <div className="title">Total geral</div>
                                <div className="icon">
                                    <i className="pi pi-clock" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-5">
                    <div className="grid area-actions">
                        <div className="col-4">
                            <Button onClick={openCheckTime} style={{width:'100%', height:'81px', fontSize:'20px'}} className="p-button-sm p-button-success" icon="pi pi-check"  iconPos="left" label="Registrar" />
                        </div>
                        {/* <div className="col-3">
                            <Button onClick={openOut} style={{width:'100%', height:'81px', fontSize:'12px'}} className="p-button-sm p-button-danger" icon="pi pi-file"  iconPos="top" label="Ausências" />
                        </div> */}
                        <div className="col-4">
                            <Button disabled style={{width:'100%', height:'81px', fontSize:'20px'}} className="p-button-sm p-button-warning" icon="pi pi-ticket" iconPos="left" label="Chamado" />
                        </div>
                        <div className="col-4">
                            <Button onClick={()=> setUpdate(new Date().getTime())} style={{width:'100%', height:'81px', fontSize:'20px'}} className="p-button-sm p-button-secondary" icon="pi pi-sync"v iconPos="left" label="Sincronizar" />
                        </div>
                    </div>
                    <Divider type="dashed" >
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-gift mr-2"></i>
                            <b>Aniversariante(s) do mês</b>
                        </div>
                    </Divider>
                    <div style={{minHeight: '250px'}}>
                        <BirthdaysList />
                    </div>
                    <Divider type="dashed" >
                        <div className="inline-flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-2"></i>
                            <b>Marcações para revisão</b>
                        </div>
                    </Divider>
                    <div style={{minHeight: '250px'}}>
                        <DataTable
                            size="small"
                            stripedRows
                            value={pendences}
                            emptyMessage="Não há pendências"
                            paginator={pendences?.length > 10}
                            rows="10"
                        >
                            <Column field="date" header="Data" />
                            <Column field="Total_Lancamentos" header="Total lançamentos" />
                        </DataTable>
                    </div>
                </div>
            </div>
        
            <Dialog header="Registrar ponto" visible={showCheckTime} style={{ maxWidth: '80%', minWidth:'300px' }}  footer={()=>{}} onHide={() => setShowCheckTime(false)}>
                <CheckTime />
            </Dialog>
             

            <Dialog header="Ausência" visible={showOut} style={{ maxWidth: '80%', minWidth:'300px' }} footer={()=>{}} onHide={() => setShowOut(false)}>
                <CheckOut />
            </Dialog>
        </div>
    )
}